import React from "react";

interface CardGridProps {
  extraClassName?: string;
  title?: string;
  description?: string;
  children?: JSX.Element;
}

const CardGrid: React.FC<CardGridProps> = ({ extraClassName, title, description, children }) => {
  return (
    <div className={`card-grid ${extraClassName ?? ""}`}>
      <div className="card-grid__container">
        {title && description && (
          <div className="card-grid__header">
            {title && (
              <div className="heading-seperator">
                <h2>{title}</h2>
              </div>
            )}
            {description && <div dangerouslySetInnerHTML={{ __html: description }}></div>}
          </div>
        )}
        <div className="card-grid__body">{children}</div>
      </div>
    </div>
  );
};

export default CardGrid;
