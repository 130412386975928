import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import { Image, TideItemForTeamContentOutro } from "../../../types";
import { parse } from "../../../utils/component";
import translate from "../../../utils/translate";
import Gallery from "../../gallery";
import translations from "./translations.json";

interface TextAndGalleryProps {
  title?: string;
  text?: string;
  images?: Image[];
  data?: TideItemForTeamContentOutro;
  venueCapacity?: number;
  anchorRef?: string;
  table: boolean;
}

const TextAndGallery: React.FC<TextAndGalleryProps> = ({ title, images, text, data, venueCapacity, anchorRef, table }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);

  // const images = parse(data?.gallery) as Image[];

  return (
    <div className="twocolumn" id={anchorRef}>
      <div className="twocolumn__container">
        <div className="twocolumn__row">
          <div className="twocolumn__column">
            {title && (
              <div className="heading-seperator">
                <h3>{title}</h3>
              </div>
            )}
          </div>
        </div>
        <div className="twocolumn__row">
          <div className="twocolumn__column">{parse(text) && <div dangerouslySetInnerHTML={{ __html: text ?? "" }}></div>}</div>
          <div className="twocolumn__column">
            {images && images.length > 0 && <Gallery images={images} />}
            {table && (
              <table className="table">
                <thead>
                  <tr>
                    <th>{translate(translations, language, "FOUNDED")}</th>
                    <th>{parse(data?.founded)}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translate(translations, language, "CAPACITY")}</td>
                    <td>{venueCapacity}</td>
                  </tr>
                  <tr>
                    <td>{translate(translations, language, "NICKNAME")}</td>
                    <td>{parse(data?.nickname)}</td>
                  </tr>
                  <tr>
                    <td>{translate(translations, language, "PLAYERS")}</td>
                    <td>{parse(data?.players)}</td>
                  </tr>
                  <tr>
                    <td>{translate(translations, language, "RIVAL")}</td>
                    <td>{parse(data?.rival)}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAndGallery;
