import { de, enGB, nlBE } from "date-fns/locale";

export const languageDescription = (language: string) => {
  switch (language) {
    case "nl-BE":
      return "NL";
    case "en-GB":
      return "EN";
    case "de-DE":
      return "DE";
  }
}

export const localeMapper = (language: string) => {
  switch (language) {
    case "en-GB":
      return enGB;
    case "de-DE":
      return de;
    default:
      return nlBE;
  }
}