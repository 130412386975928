import React from "react";
import { Image } from "../../../types";
import { parse } from "../../../utils/component";

interface SingleImageColumnProps {
  title?: string;
  image?: Image;
  caption?: string;
  centered: boolean;
  anchorRef?: string;
}

const SingleImagecolumn: React.FC<SingleImageColumnProps> = ({ title, image, caption, centered, anchorRef }) => {
  let className = "singlecolumn";
  if (centered) {
    className = "singlecolumn singlecolumn--centered";
  }
  return (
    <div className={className} id={anchorRef}>
      <div className="singlecolumn__container">
        <div className="singlecolumn__row">
          <div className="singlecolumn__column">
            {title && (
              <div className="heading-seperator">
                <h3>{title}</h3>
              </div>
            )}
            {image && <img title={parse(image?.title)} src={parse(image.url)} alt={parse(image.altText)} />}
            <span className="caption">{caption}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleImagecolumn;
