import { graphql } from "gatsby";
import React from "react";

interface SingleTextColumnProps {
  title?: string;
  text?: string;
  centered: boolean;
  anchorRef?: string;
}

const SingleTextColumn: React.FC<SingleTextColumnProps> = ({ title, text, centered, anchorRef }) => {
  let className = "singlecolumn";
  if (centered) {
    className = "singlecolumn singlecolumn--centered";
  }
  return (
    <div className={className} id={anchorRef}>
      <div className="singlecolumn__container">
        <div className="singlecolumn__row">
          <div className="singlecolumn__column">
            {title && (
              <div className="heading-seperator">
                <h3>{title}</h3>
              </div>
            )}
            {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTextColumn;

export const query = graphql`
  fragment TideItemForOutroComponentFragment on TideItemForOutroComponent {
    __typename
    id
  }
`;
