import React from "react";
import { Image } from "../../../types";
import { parse } from "../../../utils/component";
import { addWidthParameter } from "../../../utils/image-utils";

interface TextAndImageProps {
  title?: string;
  text?: string;
  image?: Image;
  reverse?: boolean;
  anchorRef?: string;
}

const TextAndImage: React.FC<TextAndImageProps> = ({ title, text, image, reverse, anchorRef }) => {
  let mainClassName = "twocolumn";
  let className = "twocolumn";
  if (reverse) {
    mainClassName = "twocolumn twocolumn--reverse";
  }

  if (!image || !image.url) {
    mainClassName = "singlecolumn singlecolumn--centered";
    className = "singlecolumn";
  }

  return (
    <div className={mainClassName} id={anchorRef}>
      <div className={`${className}__container`}>

        {title && (
          <div className={`${className}__row`}>
            <div className={`${className}__column`}>
              <div className="heading-seperator">
                <h3>{title}</h3>
              </div>
            </div>
          </div>
        )}
        <div className={`${className}__row`}>
          <div className={`${className}__column`}>
            {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
          </div>
          {image && image.url &&
            <div className={`${className}__column`}>
              <img src={addWidthParameter(parse(image?.url), 500)} className="textblock__image" alt={parse(image?.altText)} />
            </div>
          }
        </div>
      </div >
    </div >
  );
};

export default TextAndImage;
