import React, { useRef, useState } from "react";
import ProductCard, { ProductCardItem } from "../cards/product-card";
import CardGrid from "..";
import Icon from "../../icon";
import { isEmpty } from "lodash";

interface ProductCardGridProps {
  cardGridTitle?: string;
  cardGridDescription?: string;
  cardGridItems?: ProductCardItem[];
  loadingText?: string;
  loading?: boolean;
  emptyText?: string;
}

const ProductCardGrid: React.FC<ProductCardGridProps> = ({ cardGridTitle, cardGridDescription, cardGridItems, loadingText, loading, emptyText }) => {
  const container = useRef<HTMLDivElement>(null);
  const [slideAmount, setSlideAmount] = useState<number>(0);

  const slide = (left: boolean) => {
    if (container.current) {
      const containerWidth = container.current.offsetWidth;
      const translate = slideAmount + (left ? -containerWidth : containerWidth);
      setSlideAmount(translate);
    }
  };

  const checkIfDisabled = () => {
    if (container.current && cardGridItems) {
      const containerWidth = container.current.offsetWidth;
      const widthOfOneCard = containerWidth / 4;
      const maxScroll = widthOfOneCard * cardGridItems.length;
      if (slideAmount + containerWidth >= maxScroll) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <CardGrid title={cardGridTitle} description={cardGridDescription} extraClassName="card-grid--carousel">
        <div className="card-slider">
          <div className="card-slider__controls">
            <button className="card-slider__control card-slider__control--previous" disabled={slideAmount <= 0} onClick={() => slide(true)}></button>
            <button className="card-slider__control card-slider__control--next" disabled={checkIfDisabled()} onClick={() => slide(false)}></button>
          </div>
          {!loading && (
            <>
              {cardGridItems && !isEmpty(cardGridItems) && (
                <div className="card-slider__wrapper" ref={container} style={{ transform: `translateX(${-slideAmount}px)` }}>
                  {cardGridItems.map((cardGridItem, i) => (
                    <ProductCard key={i} productCardItem={cardGridItem} />
                  ))}
                </div>
              )}
              {cardGridItems && isEmpty(cardGridItems) && (
                <div className="highlight-events" dangerouslySetInnerHTML={{ __html: emptyText ?? "Geen wedstrijden weer te geven" }}></div>
              )}
            </>
          )}
          {loading && (
            <div className="preloader-spinner preloader-spinner--vertical-placement">
              <div className="preloader__icon">
                <Icon name="spinner" />
              </div>
              <div className="preloader__label">
                <div dangerouslySetInnerHTML={{ __html: loadingText ?? "De wedstrijden worden geladen" }}></div>
                <div className="preloader__dots">
                  <div className="preloader__dot-1">.</div>
                  <div className="preloader__dot-2">.</div>
                  <div className="preloader__dot-3">.</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </CardGrid>
    </>
  );
};

export default ProductCardGrid;
