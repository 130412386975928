import { BookingPackageFlight, PackageFlights } from "@qite/tide-client/build/types";
import { format } from "date-fns";
import React, { useContext } from "react";
import { getDateOnly, getWeekDay } from "../../../../utils/date-utils";
import { localeMapper } from "../../../../utils/language-mapper";
import translate from "../../../../utils/translate";
import Link from "../../../link";
import Tooltips from "../../../tooltips";
import translations from "./translations.json";
import GlobalContext, { GlobalContextType } from "../../../../contexts/global-provider";
import { Image, TideItemForHardcodedUrl } from "../../../../types";
import { parse } from "../../../../utils/component";
import Icon from "../../../icon";
import { navigate } from "gatsby";
import { addWidthParameter } from "../../../../utils/image-utils";
import { first, isEmpty } from "lodash";
import { FilterTransportItem } from "../../../../contexts/filter-provider";
import { ServiceType } from "@qite/tide-client";

interface ProductCardProps {
  productCardItem: ProductCardItem;
  transportFilter?: FilterTransportItem[];
}

export interface ProductCardItem {
  title?: string;
  image?: Image;
  homeLogo?: Image;
  awayLogo?: Image;
  path?: string;
  pathWithFlight?: string;
  pathWithoutFlight?: string;
  pathTicketOnly?: string;
  startDate?: Date;
  endDate?: Date;
  flights?: BookingPackageFlight | PackageFlights;
  flightDescription?: string;
  hotelName?: string;
  ticketName?: string;
  ticketDate?: Date;
  dateConfirmed?: boolean;
  tournamentName?: string;
  price?: number;
  priceWithoutFlight?: number;
  hasFlight: boolean;
  hasHotel: boolean;
  hasEvent: boolean;
  buttonText?: string;
  hardcodedUrls?: TideItemForHardcodedUrl[];
  packages?: ProductCardItemPackage[];
}

export interface ProductCardItemPackage {
  path?: string;
  startDate?: Date;
  endDate?: Date;
  price?: number;
  hasFlight: boolean;
  hasHotel: boolean;
  hasEvent: boolean;
  buttonText?: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ productCardItem, transportFilter }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);

  const NavigateToExternal = (e: any, url: string) => {
    e.preventDefault();
    if (url === "") return;
    if (!url.startsWith("http")) {
      url = "https://" + url;
    }
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const NavigateTo = (e: any, packageItem: ProductCardItemPackage) => {
    e.preventDefault();
    navigate(packageItem.path ? packageItem.path : "/");
  };

  const showPackage = (itemPackage: ProductCardItemPackage | null, hardcodedUrl: TideItemForHardcodedUrl | null) => {
    if (!transportFilter || isEmpty(transportFilter) || !transportFilter.some((t) => t.selected)) {
      return true;
    }

    let validPackage = false;
    if (transportFilter.find((t) => t.serviceType === ServiceType.flight && t.selected)) {
      validPackage = itemPackage?.hasFlight || first(hardcodedUrl?.content?.general?.transportIcon) === "plane";
    }

    if (transportFilter.find((t) => t.serviceType === ServiceType.event && t.selected) && !validPackage) {
      validPackage = !itemPackage?.hasFlight && first(hardcodedUrl?.content?.general?.transportIcon) !== "plane";
    }

    return validPackage;
  };

  const GetListItemTitle = (packageItem: ProductCardItemPackage) => {
    if (packageItem.hasFlight) {
      return translate(translations, language, "FLIGHTPACKAGE");
    }

    if (packageItem.hasHotel) {
      return translate(translations, language, "HOTELPACKAGE");
    }

    return translate(translations, language, "TICKETPACKAGE");
  };

  return (
    <div className="productcard">
      <Link path={productCardItem.path} className="productcard__anchor" title={productCardItem.title}>
        <div className="productcard__header">
          {productCardItem.image && (
            <img
              src={addWidthParameter(parse(productCardItem.image.url), 500)}
              title={parse(productCardItem.image.title)}
              className="productcard__header-image"
              alt={parse(productCardItem.image.altText)}
            />
          )}
          {!productCardItem.image && <img src="/images/product/productcard_voetbal.webp" className="productcard__header-image" alt="" />}
          <div className="productcard__tags">
            <div className="tags">{productCardItem.tournamentName && <div className="tags__tag">{productCardItem.tournamentName}</div>}</div>
          </div>
        </div>

        <div className="productcard__body">
          {productCardItem.homeLogo && productCardItem.awayLogo && (
            <div className="productcard__badges">
              <div className="productcard__badge">
                <img
                  src={addWidthParameter(parse(productCardItem.homeLogo.url), 70)}
                  title={parse(productCardItem.homeLogo.title)}
                  alt={parse(productCardItem.homeLogo.altText)}
                  className="productcard__badge-image"
                />
              </div>
              <div className="productcard__badge">
                <img
                  src={addWidthParameter(parse(productCardItem.awayLogo.url), 70)}
                  title={parse(productCardItem.awayLogo.title)}
                  alt={parse(productCardItem.awayLogo.altText)}
                  className="productcard__badge-image"
                />
              </div>
            </div>
          )}
          <div className="productcard__body-title">
            <h3 className="productcard__heading">{productCardItem.title}</h3>
          </div>
          <div className="productcard__body-subtitle">
            {productCardItem.startDate && productCardItem.endDate && (
              <div className="arrow-list">
                <ul>
                  <li>
                    {getWeekDay(localeMapper(language), getDateOnly(productCardItem.startDate))}{" "}
                    {format(getDateOnly(productCardItem.startDate), "dd/MM/yyyy")}
                  </li>
                  <li>
                    {getWeekDay(localeMapper(language), getDateOnly(productCardItem.endDate))}{" "}
                    {format(getDateOnly(productCardItem.endDate), "dd/MM/yyyy")}
                  </li>
                </ul>
                <div className="tooltips">
                  <div className="tooltips__tooltip">
                    <Icon name="tooltip-white" />
                    <div className="tooltips__label tooltips__label--down">
                      <span className="tooltips__label-text">{translate(translations, language, "DATE_REMARK")}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="productcard__body-specs productcard__body-specs--ticketdate">
              <div className="productcard__list">
                {productCardItem.ticketDate && (
                  <div className="productcard__listitem">
                    <div className="productcard__listitem-body">
                      <span className="productcard__listitem-property">
                        {translate(translations, language, process.env.WEBSITE === "Sportreizen" ? "EVENTDATE" : "MATCHDATE")}
                      </span>
                      <span className="productcard__listitem-value" title={format(new Date(productCardItem.ticketDate), "dd/MM/yyyy")}>
                        {!productCardItem.dateConfirmed && (
                          <div className="tooltips">
                            <div className="tooltips__tooltip">
                              <Icon name="info-triangle" />
                              <div className="tooltips__label tooltips__label--down">
                                <span className="tooltips__label-text">{translate(translations, language, "DATE_NOT_CONFIRMED")}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        {format(new Date(productCardItem.ticketDate), "dd/MM/yyyy")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="productcard__arrangements">
              <div className="productcard__arrangement-item">
                <span className="productcard__arrangement-item-title">Arrangementen:</span>
              </div>
            </div>
          </div>

          <div className="productcard__body-specs">
            <div className="productcard__list">
              {!isEmpty(productCardItem.packages) &&
                productCardItem.packages?.map((packageItem, i) => (
                  <>
                    {showPackage(packageItem, null) && (
                      <div className="productcard__listitem" key={`listItem${i}`}>
                        <div className="productcard__listitem-header">
                          <span className="productcard__listitem-property">{GetListItemTitle(packageItem)}</span>
                          <span className="productcard__listitem-value">
                            <Tooltips plane={packageItem.hasFlight} hotel={packageItem.hasHotel} ticket={packageItem.hasEvent} key={`tooltip-${i}`} />
                          </span>
                        </div>
                        <div className="productcard__listitem-body">
                          <div className="pricing">
                            {translate(translations, language, "FROM_SHORT")}
                            <span className="pricing__price">&euro; {packageItem.price?.toFixed(2)}</span>
                            {translate(translations, language, "PER_PERSON_SHORT")}
                          </div>

                          <button className="cta" title={packageItem.buttonText} type="button" onClick={(e) => NavigateTo(e, packageItem)}>
                            {packageItem.buttonText}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              {productCardItem.hardcodedUrls &&
                productCardItem.hardcodedUrls.map((hardcodedUrl, i) => (
                  <>
                    {showPackage(null, hardcodedUrl) && (
                      <div className="productcard__listitem" key={`hardcodedUrl-${i}`}>
                        <div className="productcard__listitem-header">
                          <span className="productcard__listitem-property">{hardcodedUrl.content?.general?.title}</span>
                          <span className="productcard__listitem-value">
                            <Tooltips
                              plane={first(hardcodedUrl.content?.general?.transportIcon) === "plane"}
                              ticket={!hardcodedUrl.content?.general?.noTicket}
                              bus={first(hardcodedUrl.content?.general?.transportIcon) === "bus"}
                              boat={first(hardcodedUrl.content?.general?.transportIcon) === "boat"}
                              train={first(hardcodedUrl.content?.general?.transportIcon) === "train"}
                              hotel={first(hardcodedUrl.content?.general?.stayIcon) === "hotel"}
                              tent={first(hardcodedUrl.content?.general?.stayIcon) === "tent"}
                              key={`hardcodedtooltip-${i}`}
                            />
                          </span>
                        </div>
                        <div className="productcard__listitem-body">
                          <div className="pricing">
                            {translate(translations, language, "FROM_SHORT")}
                            <span className="pricing__price">&euro; {hardcodedUrl.content?.general?.price?.toFixed(2)}</span>
                            {translate(translations, language, "PER_PERSON_SHORT")}
                          </div>

                          <button
                            className="cta"
                            title={productCardItem.buttonText}
                            type="button"
                            onClick={(e) => NavigateToExternal(e, hardcodedUrl.content?.general?.externalUrl ?? "")}
                          >
                            {productCardItem.buttonText}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
