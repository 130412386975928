import { graphql } from "gatsby";
import { get, isNil } from "lodash";
import React, { useContext } from "react";
import SingleImagecolumn from "../../components/singlecolumn/single-image-column";
import SingleTextColumn from "../../components/singlecolumn/single-text-column";
import SingleVideocolumn from "../../components/singlecolumn/single-video-column";
import QuoteImage from "../../components/twocolumn/quote-image";
import TextAndGallery from "../../components/twocolumn/text-gallery";
import TextAndImage from "../../components/twocolumn/text-image";
import TitleTextText from "../../components/twocolumn/text-text";
import {
  Image,
  Maybe,
  TideItem,
  TideItemForHighlightEventsComponent,
  TideItemForTitleImageCaptionComponent,
  TideItemForTitleImageQuoteComponent,
  TideItemForTitleImageTextComponent,
  TideItemForTitleTextComponent,
  TideItemForTitleTextGalleryComponent,
  TideItemForTitleTextImageComponent,
  TideItemForTitleTextTextComponent,
  TideItemForTitleVideoComponent,
  TideItemForWedstrijd,
} from "../../types";
import { parse } from "../component";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import PackageContext, { PackageContextType } from "../../contexts/package-provider";
import { CreateProductCardItems } from "../package-utils";
import ProductCardGrid from "../../components/cardgrid/product-cardgrid";
import translate from "../translate";
import translations from "./translations.json";

interface MapBlocksComponentsProps {
  blocks?: Maybe<TideItem>[];
  games: TideItemForWedstrijd[];
}

export const BlockTypes: string[] = [
  "TideItemForTitleTextGalleryComponent",
  "TideItemForTitleImageCaptionComponent",
  "TideItemForTitleTextComponent",
  "TideItemForTitleImageQuoteComponent",
  "TideItemForTitleTextTextComponent",
  "TideItemForTitleTextImageComponent",
  "TideItemForTitleImageTextComponent",
  "TideItemForTitleVideoComponent",
];

const MapBlocksComponents: React.FC<MapBlocksComponentsProps> = ({ blocks, games }) => {
  const { tournaments, teams } = useContext<ComponentContextType>(ComponentContext);
  const { packages, packagesLoading } = useContext<PackageContextType>(PackageContext);
  const { language, affiliate } = useContext<GlobalContextType>(GlobalContext);
  const componentRenderers = {
    TideItemForTitleTextGalleryComponent: (componentItem: TideItemForTitleTextGalleryComponent) => {
      const images = parse(componentItem.content?.general?.gallery) as Image[];
      return (
        <TextAndGallery
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          text={parse(componentItem.content?.general?.text)}
          images={images}
          table={false}
          anchorRef={parse(componentItem.content?.general?.title)}
        />
      );
    },
    TideItemForTitleImageCaptionComponent: (componentItem: TideItemForTitleImageCaptionComponent) => {
      return (
        <SingleImagecolumn
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          caption={parse(componentItem.content?.general?.caption)}
          image={parse(componentItem.content?.general?.image)}
          centered={true}
          anchorRef={parse(componentItem.content?.general?.title)}
        />
      );
    },
    TideItemForTitleTextComponent: (componentItem: TideItemForTitleTextComponent) => {
      return (
        <SingleTextColumn
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          text={parse(componentItem.content?.general?.text)}
          centered={false}
          anchorRef={parse(componentItem.content?.general?.title)}
        />
      );
    },
    TideItemForTitleImageQuoteComponent: (componentItem: TideItemForTitleImageQuoteComponent) => {
      return (
        <QuoteImage
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          author={parse(componentItem.content?.general?.author)}
          text={parse(componentItem.content?.general?.quote)}
          image={parse(componentItem.content?.general?.image)}
          reverse={false}
          anchorRef={parse(componentItem.content?.general?.title)}
        />
      );
    },
    TideItemForTitleTextTextComponent: (componentItem: TideItemForTitleTextTextComponent) => {
      return (
        <TitleTextText
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          text1={parse(componentItem.content?.general?.text1)}
          text2={parse(componentItem.content?.general?.text2)}
          anchorRef={parse(componentItem.content?.general?.title)}
        />
      );
    },
    TideItemForTitleTextImageComponent: (componentItem: TideItemForTitleTextImageComponent) => {
      return (
        <TextAndImage
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          text={parse(componentItem.content?.general?.text)}
          image={parse(componentItem.content?.general?.image)}
          reverse={false}
          anchorRef={parse(componentItem.content?.general?.title)}
        />
      );
    },
    TideItemForTitleImageTextComponent: (componentItem: TideItemForTitleImageTextComponent) => {
      return (
        <TextAndImage
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          text={parse(componentItem.content?.general?.text)}
          image={parse(componentItem.content?.general?.image)}
          reverse={true}
          anchorRef={parse(componentItem.content?.general?.title)}
        />
      );
    },
    TideItemForTitleVideoComponent: (componentItem: TideItemForTitleVideoComponent) => {
      return (
        <SingleVideocolumn
          key={componentItem.id}
          title={parse(componentItem.content?.general?.title)}
          youtubeCode={parse(componentItem.content?.general?.youtubecode)}
          anchorRef={parse(componentItem.content?.general?.title)}
          caption={parse(componentItem.content?.general?.caption)}
        />
      );
    },
    TideItemForHighlightEventsComponent: (componentItem: TideItemForHighlightEventsComponent) => {
      const highlightTagId = componentItem.content?.general?.tagId;
      if (highlightTagId) {
        const gamesWithTagId = games.filter((g) => g.content?.general?.tagsIds?.includes(highlightTagId));
        const productCardItems =
          CreateProductCardItems(
            packages || [],
            translate(translations, language, "SHOW_TRIP"),
            true,
            teams,
            tournaments,
            gamesWithTagId,
            affiliate
          ) ?? [];
        return (
          <ProductCardGrid
            cardGridTitle={parse(componentItem.content?.general?.title)}
            cardGridItems={productCardItems}
            loadingText={parse(componentItem.content?.general?.textWhileLoading)}
            emptyText={parse(componentItem.content?.general?.textWhenEmpty)}
            loading={packagesLoading}
            key={componentItem.id}
          ></ProductCardGrid>
        );
      }
    },
  };

  return (
    <>
      {blocks &&
        blocks.map((tideItem) => {
          const typeName = get(tideItem, "__typename");
          const renderer = get(componentRenderers, typeName);

          if (!isNil(renderer)) {
            return renderer(tideItem);
          }

          return null;
        })}
    </>
  );
};

export default MapBlocksComponents;

export const query = graphql`
  fragment TideItemForInfoBlocksComponentFragment on TideItemForInfoBlocksComponent {
    __typename
    id
    childItems {
      ...BlocksFragment
    }
  }
  fragment BlocksFragment on TideItem {
    ...TitleImageCaptionFragment
    ...TitleImageQuoteFragment
    ...TitleTextFragment
    ...TitleTextGalleryFragment
    ...TitleTextImageFragment
    ...TitleImageTextFragment
    ...TitleTextTextFragment
    ...TitleVideoFragment
    ...HighlightEventsComponentFragment
  }
  fragment TitleImageCaptionFragment on TideItemForTitleImageCaptionComponent {
    __typename
    id
    name
    content {
      general {
        title
        image {
          title
          altText
          url
        }
        caption
      }
    }
  }
  fragment TitleImageQuoteFragment on TideItemForTitleImageQuoteComponent {
    __typename
    id
    name
    content {
      general {
        title
        quote
        author
        image {
          title
          altText
          url
        }
      }
    }
  }
  fragment TitleTextFragment on TideItemForTitleTextComponent {
    __typename
    id
    name
    content {
      general {
        title
        text
      }
    }
  }
  fragment TitleTextGalleryFragment on TideItemForTitleTextGalleryComponent {
    __typename
    id
    name
    content {
      general {
        title
        text
        gallery {
          title
          altText
          url
        }
      }
    }
  }
  fragment TitleTextImageFragment on TideItemForTitleTextImageComponent {
    __typename
    id
    name
    content {
      general {
        title
        text
        image {
          title
          altText
          url
        }
      }
    }
  }
  fragment TitleImageTextFragment on TideItemForTitleImageTextComponent {
    __typename
    id
    name
    content {
      general {
        title
        text
        image {
          title
          altText
          url
        }
      }
    }
  }
  fragment TitleTextTextFragment on TideItemForTitleTextTextComponent {
    __typename
    id
    name
    content {
      general {
        title
        text1
        text2
      }
    }
  }
  fragment TitleVideoFragment on TideItemForTitleVideoComponent {
    __typename
    id
    name
    content {
      general {
        title
        youtubecode
        caption
      }
    }
  }
  fragment HighlightEventsComponentFragment on TideItemForHighlightEventsComponent {
    __typename
    id
    name
    content {
      general {
        title
        tagId
        textWhenEmpty
        textWhileLoading
      }
    }
  }
`;
