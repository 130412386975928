import React, { useState } from "react";
import { Image } from "../../types";
import Lightbox from "react-image-lightbox";
import { setImageParameters } from "../../utils/set-image-parameters";

const VISIBLE_IMAGES_COUNT = 5;

interface GalleryProps {
  images: Image[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);

  const extraImageCount = (images ?? []).length - VISIBLE_IMAGES_COUNT;

  const handleImageClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    openGallery(parseInt(e.currentTarget.dataset.index ?? "0"));
  };

  const handleMoreClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();

    openGallery(VISIBLE_IMAGES_COUNT - 1);
  };

  const openGallery = (index: number) => {
    setPhotoIndex(index);
    setOpen(true);
  };

  return (
    <div className="gallery">
      <div className="gallery__main-image">
        <a onClick={handleImageClick} data-index={0} className="gallery__anchor">
          <img
            src={setImageParameters(images[0]?.url ?? undefined, {
              width: 1000,
              height: 800,
              mode: "min",
            })}
            alt={images[0]?.altText ?? undefined}
            className="media-item__media"
          />
        </a>
      </div>

      <div className="gallery__thumbnails">
        {images.slice(1, VISIBLE_IMAGES_COUNT).map((image, index) => (
          <div key={index} className="gallery__thumbnail">
            <a onClick={handleImageClick} data-index={index + 1} className="media-item__link">
              <img
                src={setImageParameters(image.url ?? undefined, {
                  width: 1000,
                  height: 800,
                  mode: "min",
                })}
                alt={image.altText ?? undefined}
                className="gallery__image"
              />
            </a>
            {extraImageCount && index + 1 === VISIBLE_IMAGES_COUNT - 1 && (
              <span onClick={handleMoreClick} className="gallery__thumbnail--extra" data-imagecount={`+${extraImageCount}`}></span>
            )}
          </div>
        ))}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]?.url ?? ""}
          imageTitle={images[photoIndex]?.title}
          nextSrc={images[(photoIndex + 1) % images.length].url ?? undefined}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].url ?? undefined}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default Gallery;
