import React from "react";

interface SingleVideoColumnProps {
  title?: string;
  youtubeCode?: string;
  anchorRef?: string;
  caption?: string;
}

const SingleVideocolumn: React.FC<SingleVideoColumnProps> = ({ title, youtubeCode, anchorRef, caption }) => {
  return (
    <div className="singlecolumn singlecolumn--centered" id={anchorRef}>
      <div className="singlecolumn__container">
        <div className="singlecolumn__row">
          <div className="singlecolumn__column">
            {title && (
              <div className="heading-seperator">
                <h3>{title}</h3>
              </div>
            )}
            {youtubeCode && (
              <iframe
                width="1000"
                height="400"
                src={`https://www.youtube-nocookie.com/embed/${youtubeCode}`}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            <span className="caption">{caption}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVideocolumn;
