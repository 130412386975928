import React from "react";

interface TitleTextTextProps {
  title?: string;
  text1?: string;
  text2?: string;
  anchorRef?: string;
}

const TitleTextText: React.FC<TitleTextTextProps> = ({ title, text1, text2, anchorRef }) => {
  return (
    <div className="twocolumn" id={anchorRef}>
      <div className="twocolumn__container">
        <div className="twocolumn__row">
          <div className="twocolumn__column">
            {title && (
              <div className="heading-seperator">
                <h3>{title}</h3>
              </div>
            )}
          </div>
        </div>
        <div className="twocolumn__row">
          <div className="twocolumn__column">{text1 && <div dangerouslySetInnerHTML={{ __html: text1 }}></div>}</div>
          <div className="twocolumn__column">{text2 && <div dangerouslySetInnerHTML={{ __html: text2 }}></div>}</div>
        </div>
      </div>
    </div>
  );
};

export default TitleTextText;
